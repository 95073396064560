<!--  -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <el-input placeholder="客户名称" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;" @input="curr=1;getList()"></el-input>
            <el-select v-model="select.site_id" placeholder="销售站点" :clearable="true" size="small" @change="curr=1;getList()">
                <el-option v-for="(item,index) in siteList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="select.sales_id" placeholder="业务员" clearable style="width:140px" @change="curr=1;getList()">
                <el-option v-for="(item,index) in yewu" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
            <el-date-picker style="width:230px" v-model="select.create" type="daterange" range-separator="至" start-placeholder="创建日期" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
            </el-date-picker>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column label="订单" min-width="270">
                <template slot-scope="scope">
                    <div class="order-summary">
                        <i class="el-icon-s-platform"></i>
                        <span class="cs-mr">订单号：{{scope.row.order_sn}}</span>
                        <span>创建日期：{{scope.row.create_time}}</span>
                    </div>

                    <div v-for="(goods, index) in scope.row.orderDetails" :key="index" class="flex" style="margin-bottom:10px">
                        <el-image style="width: 70px; height: 70px;" :src="getImgUrl(goods.product_pic)" :preview-src-list="getImgUrl(goods.product_pic,'arr')" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline" style="font-size:70px"></i>
                            </div>
                        </el-image>
                        <div class="goods-info order-text" style="margin-left:15px">
                            <p>{{goods.product_name}}</p>
                            <p class="son">价格：{{goods.price }} x {{goods.number}}</p>
                            <p class="son">小计：{{goods.subtotal }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="所属站点" prop="site.name" show-overflow-tooltip></el-table-column>
            <el-table-column prop="sales.name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="amount" label="订单金额(元)" align="center" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="订单金额(元)">
                <template slot-scope="scope">
                    <div class="order-text">
                        <p class="son">应退款：<span style="color:#F56C6C">{{scope.row.pay_amount}}</span></p>
                        <p class="son">实退款：{{scope.row.amount}}</p>
                    </div>
                </template>
            </el-table-column> -->
            <el-table-column label="买家" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="order-text" v-if="scope.row.user">
                        <p class="son ellipsis">客户名称：{{scope.row.user.name}}</p>
                        <p class="son">联系人：{{scope.row.user.contacts}}</p>
                        <p class="son">手机：{{scope.row.user.mobile}}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="reason" label="退单原因" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- <p class="ellipsis">{{scope.row.status==1?'已通过':scope.row.status==2?'已拒绝':'未审核'}}</p> -->
                    <el-tag size="small" type="success" v-if="scope.row.type_status==1">已通过</el-tag>
                    <el-tag size="small" type="danger" v-else-if="scope.row.type_status==2">已拒绝</el-tag>
                    <el-tag size="small" type="primary" v-else>待审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="拒绝原因" prop="type_reason" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="flex flex-column">
                        <el-link class="order-button" :disabled="scope.row.type_status!=3" type="success" @click="handleAudit(scope.row,1)" :underline="false">审核通过</el-link>
                        <el-link class="order-button" :disabled="scope.row.type_status!=3" type="danger" @click="handleAudit(scope.row,2)" :underline="false">审核拒绝</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>


    <!-- 新建编辑 -->
    <!-- <el-dialog :title="dialogTitle" :modal-append-to-body="false" :close-on-click-modal="false" :visible="dialogIsShow" width="60%" @close="dialogClose">
        <el-form :model="formData" ref="form" label-position="top" size="small" :show-message="false" :status-icon="true" class="alertForm">
            <el-form-item label="购买客户" prop="user_id" required>
                <el-select v-model="formData.user_id" filterable placeholder="请选择">
                    <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="实付金额" prop="amount" required>
                <el-input v-model="formData.amount" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose" size="small">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" class="save" size="small">保 存</el-button>
        </div>
    </el-dialog> -->
</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            yewu:[],


            // formData: {},
            // dialogTitle: '',
            // dialogIsShow: false,
            siteList: [],
        }
    },
    mounted: function() {
        this.getList();
        this.getAdmin()
    },
    methods: {
        //站点
        getAdmin: function() {
            this.http.post('/sales.site/getList',{
                curr:1,
                row:99999
            }).then(re => {
                this.siteList = re.data.list;
            })

            this.http.post('/admin.admin/getSalesList',{},false).then(re=>{
                this.yewu = re.data
            })
        },
        handleAudit(row, stateId) {
            if (stateId == 2) { //拒绝时
                this.$prompt(' ', '审批拒绝', {
                    inputPlaceholder: '请填写拒绝原因',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    inputPattern: /\S/,
                    inputErrorMessage: '不得为空'
                }).then(({
                    value
                }) => {
                    this.http.post('/admin.order/refundStatus', {
                        type_reason: value,
                        id: row.id,
                        type_status: stateId
                    }).then(() => {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '已拒绝!'
                        });
                    })
                }).catch(() => {});
            } else {
                this.$confirm('确认审核通过吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.http.post('/admin.order/refundStatus', {
                        id: row.id,
                        type_status: stateId
                    }).then(() => {
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '已通过!'
                        });
                    })
                })
            }
        },
        // 图片转换
        getImgUrl(item, type) {
            var url
            if (type == 'arr') {
                url = [this.domain + item];
            } else {
                url = this.domain + item;
            }
            return url;
        },
        getList: function() {
            this.http.post('/admin.order/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue,
                type: 2,
                type_status: 3,
                site_id:this.select.site_id,
                create:this.select.create,
                sales_id:this.select.sales_id,
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
<style media="screen" lang="less">
.order-summary {
    margin-bottom: 10px;
    color: #b3b6bb;
    .cs-mr {
        margin-left: 10px;
        margin-right: 30px;
    }
}
.son {
    color: #909399;
}
</style>
